import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from "styled-components";
import Navbar from "../app/Navbar";
import Footer from "../app/Footer";
import backgroundJPG from '../assets/BGIWithoutCharacterCompressed.jpg';
import { useAuth } from './AuthContext';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh; /* 使容器高度充满整个视口高度 */
  width: 100vw;
  position: relative;
  background: url(${backgroundJPG}) no-repeat center center;
  background-size: cover;
`;

const ContentContainer = styled.div`
  flex-grow: 1; /* 允许内容区域填充所有可用空间 */
  overflow: auto; /* 如果内容超过视口高度，允许滚动 */
  padding-top: 20px; /* 顶部padding */
  padding-bottom: 20px; /* 底部padding */
`;


interface AuthenticatedLayoutProps {
  children: React.ReactNode;
  notChatPage?: boolean;
}

const AuthenticatedLayout = ({ children, notChatPage = true }: AuthenticatedLayoutProps) => {
  const { isLoggedIn } = useAuth();
  const allowedModelId = ['ovo0SqW3SS'];
  const location = useLocation();
  const isAllowedChatPath = allowedModelId.some(id => location.pathname === `/chat/${id}`);
  // If the user is not logged in and the current path is not one of the allowed chat paths, navigate them to the login page
  if (!isLoggedIn && !isAllowedChatPath) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Section>
        <Navbar />
        <ContentContainer>
          {children}
        </ContentContainer>
      </Section>
      {
        notChatPage && ( <Footer />)
      }
    </>
  );
};

export default AuthenticatedLayout;
