import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Progress, Typography, message, Button } from 'antd';
import styled from 'styled-components';
import { useRedirectToNewPage } from '../../../util/redirectToNewPage';
import { usePkSystemHook } from '../../../state/pk-system-hook';

const { Title, Text } = Typography;

const TokenUsageCard = styled(Card)`
  background-color: #f0f2f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TokenUsagePage: React.FC = () => {
  const [state] = usePkSystemHook();
  const [tokenUsage, setTokenUsage] = useState({ used: state.user.profile.usageCount, total: state.user.profile.maxUsageCount });

  useEffect(() => {
    setTokenUsage({
      used: state.user.profile.usageCount,
      total: state.user.profile.maxUsageCount
    });
  }, [state.user.profile.usageCount, state.user.profile.maxUsageCount]);
  

  const usagePercent = (tokenUsage.used / tokenUsage.total) * 100;

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        padding: '15rem',
        position: 'relative' // 添加相对定位
      }}
    >
      <Col xs={24} sm={18} md={12} lg={10} xl={8}>
        <TokenUsageCard>
          <Title level={3} style={{ textAlign: 'center' }}>使用情况</Title>
          <Progress percent={usagePercent} status="active" showInfo={false} />
          <Text style={{ display: 'block', textAlign: 'center', marginTop: '1rem' }}>
            已使用 {tokenUsage.used} / {tokenUsage.total}
          </Text>
        </TokenUsageCard>
      </Col>
    </Row>
  );

};

export default TokenUsagePage;
