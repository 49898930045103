import TokenUsagePage from './register/profile/TokenUsagePage';
import AuthenticatedLayout from '../app/AuthenticatedLayout';

const UsageManagement = () => {
    return (
        <AuthenticatedLayout>
            <TokenUsagePage />
        </AuthenticatedLayout>
    );
};

export default UsageManagement;
