import React, { useState, useEffect } from 'react';
import Loading from '../../app/Loading';
import axios from 'axios';

// 步骤 1: 定义Props类型
interface FileFetcherProps {
    waitTime: number;
    fileUrl: string;
}

// 步骤 2: 应用Props类型到组件
const FileFetcher: React.FC<FileFetcherProps> = ({ waitTime, fileUrl }) => {
    const [isLoading, setIsLoading] = useState(false);


    const downloadFile = (url: string) => {
        try {
            // 假设所有文件都在public文件夹下的assets目录中
            // 并且url仅包含文件名，例如 'example.png'
            const downloadUrl = `${process.env.PUBLIC_URL}/assets/${url}`;

            const link = document.createElement('a');
            link.href = downloadUrl;

            // 提取并使用文件名
            const fileName = url.split('/').pop() ?? '';
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Download file error:', error);
        }
    };



    useEffect(() => {
        const fetchFile = async () => {
            setIsLoading(true);
            await new Promise(resolve => setTimeout(resolve, waitTime));
            await downloadFile(fileUrl);
            setIsLoading(false);
            console.log('File fetched:', fileUrl);
        };

        if (fileUrl) {
            fetchFile();
        }
    }, [fileUrl, waitTime]);

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <></>
            )}
        </div>
    );
};

export default FileFetcher;
