const MyBotTagItems = [
  '友好',
  '幽默',
  '严肃',
  '热情',
  '活泼',
  '机智',
  '负责',
  '自信',
  '乐观',
  '善良',
  '开朗',
  '温柔',
  '稳重',
  '独立',
  '务实',
  '内敛',
  '果断',
  '谨慎',
  '慷慨',
  '忠诚',
  '谦逊',
  '犹豫',
  '直率',
  '颓废',
  '矛盾',
  '轻浮',
  '悲观',
  '敏感',
  '充满活力',
  '平静',
  '感性',
  '理性',
  '拖延',
  '自律',
  '怀疑',
  '嘲讽',
  '坚定',
  '迷茫',
  '急躁',
  '耐心',
  '自负',
  '虚荣',
];

export default MyBotTagItems;
