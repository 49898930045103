import React, { useEffect, useState } from 'react';
import { usePkSystemHook } from '../../state/pk-system-hook';
import { IMessage } from '../../types/IMessage';
import './style.css';
import { AppUser } from '../../state/pk-system-state';
import FileFetcher from './FileFetcher';

interface Props {
  messages: { text: string; id: string; sender: AppUser, citation?: string[] }[];
  user: AppUser;
  isBotTyping: boolean;
}
const isUser = (user: AppUser, message: IMessage) => {
  return user.id === message.sender.id;
};

const getRenderName = (isUser: boolean, message: IMessage) => {
  let renderName;
  if (isUser) {
    renderName = null;
  } else {
    renderName = (
      <div className="sender-name">{message.sender.profile.username}</div>
    );
  }
  return renderName;
};

const console = require('console-browserify');
export const MessageListDetail = ({
  messages,
  user,
  isBotTyping,
}: Props) => {
  const [state] = usePkSystemHook();
  // 新增状态来控制FileFetcher的显示和参数
  const [fetcherParams, setFetcherParams] = useState({ show: false, waitTime: 0, fileUrl: '' });

  // 定义处理图标点击的函数
  const handleIconClick = (waitTime: number, fileUrl: string) => {
    setFetcherParams({ show: true, waitTime, fileUrl });
  };

  useEffect(() => {
  }, [state.messageList, state.user.id]);
  return (
    <div className="chatSection">
      {messages.map((message, index) => {
        return (
          <div
            key={`${message.id}-${index}`}
            className="chat-bubble-row"
          >
            <img
              src={message.sender.profile.avatar}
              alt="未命名"
              className="avatar"
              style={
                isUser(user, message)
                  ? { marginLeft: '15px' }
                  : { marginRight: '15px' }
              }
            />
            <div className='chat-bubble-container'>
              <div
                className={`chat-bubble ${isUser(user, message) ? 'is-user' : 'is-other'
                  }`}
              >
                <div className='message-sender-name'>
                  {getRenderName(isUser(user, message), message)}
                </div>
                <div className='message-text'>
                  {message.text}
                </div>
                {message.citation && message.citation.length > 0 && (
                  <div className='message-citation'>
                    {message.citation && message.citation.length > 0 && (
                      <>
                        <div>Source:</div>
                        {message.citation.map((citation, index) => (
                          <div key={index}>{index + 1}. {citation}</div> // 为每个citation创建一个新的div，并添加序号
                        ))}
                      </>
                    )}
                  </div>
                )}

              </div>
              {
              // message.citation && message.citation.length > 0 && (
              // <div className="icons-container">
              //   {/* <img src={"https://s2.loli.net/2024/03/06/QlF5BbRG94E2Zqd.png"} alt="Copy" className="icon" /> */}
              //   <img src={"https://s2.loli.net/2024/03/06/3bh4piOUu6BL7gS.png"} alt="Image" className="icon" onClick={() => handleIconClick(10000,'2.png')}/>
              //   <img src={"https://s2.loli.net/2024/03/03/q7Zfsel1AMICrF5.png"} alt="PPT" className="icon" onClick={() => handleIconClick(20000, '1.pptx')}/>
              //   <img src={"https://s2.loli.net/2024/03/06/Fx1aIX67df5ZqBT.png"} alt="Video" className="icon"/>
              // </div>
              // )
              }
            </div>
          </div>
        );
      })}
      {fetcherParams.show && <FileFetcher waitTime={fetcherParams.waitTime} fileUrl={fetcherParams.fileUrl} />}
      {isBotTyping && (
        <div className="chat-bubble-row">
          <div className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};
